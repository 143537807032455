/* Width of emoji used by user to display status. */
$user_status_emoji_width: 24px;

.right-sidebar {
    a:hover {
        text-decoration: none;
    }
}

#buddy_list_wrapper {
    position: relative;
    margin-left: 0;
    overflow: auto;
}

#user_presences {
    max-width: 95%;
    overflow-x: hidden;
    list-style-position: inside; /* Draw the bullets inside our box */

    li {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        list-style-type: none;
        border-radius: 4px;
        padding-right: 15px;
        padding-top: 1px;
        padding-bottom: 2px;

        .user-list-sidebar-menu-icon {
            position: absolute;
            top: 1px;
            right: 0;
            font-size: 1em;
            display: none;
            text-align: center;
            padding: 0 6px;

            i {
                padding-right: 0.25em;
                display: inline-block;
                width: 13px;
                vertical-align: middle;
            }

            &:hover {
                display: inline;
                cursor: pointer;
                color: hsl(0, 0%, 0%) !important;
            }

            /*
            Hover does not work for touch-based devices like mobile phones.
            Hence the the icons does not appear, making the user unaware of its
            presence on such devices. The following media property displays the
            icon by default for such behaviour.
            */

            @media (hover: none) {
                display: inline;
            }
        }

        &:hover {
            .user-list-sidebar-menu-icon {
                display: inline;
                cursor: pointer;
                color: hsl(0, 0%, 53%);
            }
        }

        &:hover,
        &.highlighted_user {
            background-color: hsla(120, 12.3%, 71.4%, 0.38);
        }
    }

    .user_circle {
        width: 8px;
        height: 8px;
        margin: 0 5px;
        display: block;
    }

    a {
        color: inherit;
        margin-left: 0;
    }
}

#invite-user-link i {
    text-decoration: none;
    margin-right: 3px;
    margin-left: 5px;
}

.user-presence-link,
.user_sidebar_entry .selectable_sidebar_block {
    display: flex;
    overflow: hidden;

    .user-name {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.user_sidebar_entry .selectable_sidebar_block {
    width: calc(100% - 16px);
    display: flex;
    flex-direction: row;
}

.user-name-and-status-wrapper {
    display: block;
    width: 100%;
}

.user-presence-link {
    width: calc(100% - $user_status_emoji_width);

    .status_emoji {
        top: 9px;
    }
}

.my_user_status {
    opacity: 0.5;
}

.selectable_sidebar_block {
    cursor: pointer;
}

.user_list_style_values {
    .user-name-and-status-emoji {
        display: block;
        width: 100%;
        height: 20px;

        .user-name {
            display: inline-block;
            max-width: calc(100% - $user_status_emoji_width);
            overflow-x: hidden;
            text-overflow: ellipsis;
        }

        .status_emoji {
            line-height: 20px;
            top: -2px;
        }
    }

    .status-text {
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
}

.user_sidebar_entry {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .user-name-and-status-emoji {
        display: flex;
    }

    .status-text {
        display: block;
        width: 170px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: 0.75;
        font-size: 90%;
    }

    span.status-text:not(:empty) {
        margin-top: -3px;
    }

    .unread_count {
        display: none;
        margin-top: 2.5px;
    }

    &.user-with-count .unread_count {
        display: block;
        margin-left: 4px;
    }
}

#userlist-toggle {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    vertical-align: middle;
    border-left: 1px solid hsl(0, 0%, 88%);
}

#userlist-toggle-button {
    text-decoration: none;
    color: hsl(0, 0%, 60%);
    display: block;
    width: 45px;
    height: 19px;
    padding-top: 12px;
    padding-bottom: 9px;

    &:hover {
        color: inherit;
    }
}

.right-sidebar-items:first-of-type #userlist-header {
    border-top: none;
}

#userlist-header {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;

    #userlist-title {
        margin: 0;
    }

    #user_filter_icon {
        opacity: 0.5;
        margin-right: 5px;

        &:hover {
            opacity: 1;
            cursor: pointer;
        }
    }

    /* hovering over the userlist-header creates the same highlight effect as hovering over the user_filter_icon */
    &:hover > #user_filter_icon {
        opacity: 1;
        cursor: pointer;
    }
}

#keyboard-icon {
    position: relative;
    cursor: pointer;
    font-size: 20px;
    margin-right: 15px;
}

#sidebar-keyboard-shortcuts {
    color: inherit;
}

.right-sidebar-shortcuts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
}

@media (width < $xl_min) {
    #userlist-toggle {
        display: block;
    }
}

@media (width < $md_min) {
    #user_search_section .user-list-filter {
        /* input should be 100% - 6px padding x2 - 1px border x2. */
        width: calc(100% - 12px - 2px);
    }
}

#user_search_section {
    input {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 20px;
        width: calc(100% - 40px - 3px);
    }
}

@media (width < $sm_min) {
    #userlist-toggle {
        height: 30px;
        line-height: 30px;
    }

    #userlist-toggle-button {
        height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.right-sidebar-shortcuts .realm-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    width: 230px;

    .color_animated_button {
        width: 100%;
        margin-bottom: 10px;
    }

    hr {
        width: 90%;
    }
}
