.message_reactions {
    padding-left: 46px;
    overflow: hidden;

    user-select: none;

    .message_reaction {
        display: flex;
        float: left;
        margin: 0.15em;
        padding: 0 2px 0 0;
        cursor: pointer;
        background-color: hsl(0, 0%, 100%);
        border: 1px solid hsl(194, 37%, 84%);
        border-radius: 4px;
        align-items: center;

        &.reacted {
            background-color: hsl(195, 50%, 95%);
        }

        &:hover {
            border: 1px solid hsl(200, 100%, 40%);
        }

        + .reaction_button {
            visibility: hidden;
            pointer-events: none;
            margin: 2px 0.1em 3px;
            padding: 3px;
            height: 13px;
            border-radius: 4px;
            padding-left: 0.3em;
            border: 1px solid hsl(0, 0%, 73%);
            padding-right: 0.3em;
            float: left;
        }

        .emoji {
            display: inline-block;
            flex-shrink: 0;
            vertical-align: top;
            top: 0;
            margin: 1px 3px;
            height: 17px;
            width: 17px;
            position: relative;
        }
    }

    .message_reaction_count {
        position: relative;
        top: 1px;
        font-size: 90%;
        display: flex;
        margin: 0 3px;
        padding: 2px 0;
        line-height: 1em;
    }

    .message_reaction:hover .message_reaction_count {
        color: hsl(200, 100%, 40%);
    }

    i {
        font-size: 1.3em;
        float: left;
        color: hsl(0, 0%, 33%);
    }

    &:hover .message_reaction + .reaction_button {
        visibility: visible;
        pointer-events: all;
        background-color: hsl(0, 0%, 98%);
    }

    .reaction_button {
        display: flex;
        align-items: center;

        i {
            font-size: 1em;
        }

        &:hover i {
            color: hsl(200, 100%, 40%);
        }

        /* Configure the reaction button to appear if and only if there's an
           existing reaction to the message. We reference first-child
           rather than only-child because tooltips may be appended to
           the DOM after this element, whereas actual reactions are
           appended before it. */
        &:first-child {
            display: none;
        }

        &:hover {
            border: 1px solid hsl(200, 100%, 40%);
            background-color: hsl(195, 50%, 95%);
            cursor: pointer;
            opacity: 1;
            color: hsl(200, 100%, 40%);
        }

        .message_reaction_count {
            font-size: 1.1em;
            color: hsl(0, 0%, 33%);
            margin-right: 0;
        }

        &:hover .message_reaction_count {
            color: hsl(200, 100%, 40%);
        }
    }
}

.private-message .message_reactions .message_reaction {
    background-color: hsl(192, 20%, 95%);

    &.reacted {
        background-color: hsl(196, 51%, 93%);
        border-color: hsl(193, 38%, 70%);
    }
}

.reaction_button_visible {
    visibility: visible !important;
    pointer-events: all !important;
    opacity: 1 !important;
}

.emoji-info-popover {
    padding: 0;
    height: 370px;
    user-select: none;

    .popover-content {
        padding: 0;
    }

    &.bottom .arrow {
        border-bottom-color: hsl(0, 0%, 93%);
    }

    &.top .arrow {
        border-top-color: hsl(0, 0%, 93%);
    }

    .emoji-popover {
        display: block;
        width: 250px;

        .reacted {
            background-color: hsl(195, 50%, 95%);
            border-color: hsl(195, 52%, 79%);
        }

        .emoji-popover-top {
            position: relative;

            padding: 8px 10px;
            margin-bottom: 0;

            background-color: hsl(0, 0%, 93%);

            border-radius: 5px 5px 0 0;

            .fa-search {
                position: absolute;
                color: hsl(0, 0%, 73%);
                top: 15px;
                left: 17px;
                z-index: 3;
            }

            .emoji-popover-filter {
                margin: auto;
                padding-left: 22px;
                width: calc(100% - 22px - 8px);
                font-size: 90%;
            }
        }

        .emoji-popover-category-tabs {
            /* Flex needed here to work around #7511 (90% zoom issues in firefox) */
            display: flex;
            background-color: hsl(0, 0%, 93%);
            width: 100%;
            box-sizing: border-box;
            overflow: hidden;

            .emoji-popover-tab-item {
                display: inline-block;
                padding-top: 8px;
                width: 25px;
                height: 25px;
                text-align: center;
                font-size: 16px;
                cursor: pointer;
                /* Flex needed here to work around #7511 (90% zoom issues in firefox) */
                flex-grow: 1;

                &.active {
                    background-color: hsla(0, 0%, 100%, 0.2);
                }
            }
        }

        .emoji-popover-emoji-map,
        .emoji-search-results-container {
            padding: 0;
            position: relative;
            overflow-x: hidden;
            overflow-y: auto;
            display: block;
            width: 247px;
            padding-left: 3px;
        }

        .emoji-search-results-container {
            height: 283px;

            .emoji-popover-results-heading {
                font-weight: 600;
                padding: 5px 3px 3px 5px;
                font-size: 17px;
            }
        }

        .emoji-popover-emoji-map {
            height: 250px;

            .emoji-popover-subheading {
                font-weight: 600;
                padding: 5px 3px;
            }
        }

        .emoji-popover-emoji {
            display: inline-block;
            margin: 0;
            padding: 6px;
            cursor: pointer;
            border-radius: 0.5em;
            height: 25px;
            width: 25px;

            &.reacted.reaction:focus {
                background-color: hsl(195, 55%, 88%);
                outline: none;
            }

            &:not(.reacted):focus {
                background-color: hsl(0, 0%, 93%);
                outline: none;
            }

            &.hide {
                display: none;
            }

            .emoji {
                top: 6px;
            }
        }
    }

    .emoji-showcase-container {
        position: relative;
        background-color: hsl(0, 0%, 93%);
        min-height: 44px;
        width: 250px;

        .emoji-preview {
            position: absolute;
            width: 32px;
            height: 32px;
            left: 5px;
            top: 6px;
            margin-top: 0;
        }

        .emoji-canonical-name {
            position: relative;
            top: 12px;
            margin-left: 50px;
            font-size: 16px;
            font-weight: 600;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.emoji_alt_code {
    position: relative;
    top: 4px;
    font-size: 0.8em;
    display: inline-block;
    vertical-align: top;
    margin: 0 1px 0 0;
    line-height: 1em;
}

.typeahead .emoji {
    top: 2px;
}
