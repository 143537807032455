@import url("flatpickr/dist/themes/dark.css");

:root.dark-theme {
    /* the following block(s) are generated by the import statements.
       See postcss.config.js for details.
     */
    @extend %dark-theme-block;

    body {
        color: hsl(236, 33%, 90%);
    }

    .placeholder {
        color: hsl(0, 0%, 55%);
        opacity: 1;
    }

    textarea::placeholder,
    input::placeholder {
        @extend .placeholder;
    }

    a:hover {
        color: hsl(200, 79%, 66%);
    }

    ul.filters a:hover {
        color: inherit;
    }

    /************************* MODAL DARK THEME *******************/
    .dialog_cancel_button {
        background-color: hsl(211, 29%, 14%);
        color: hsl(0, 0%, 100%);
        border: 1px solid hsla(0, 0%, 0%, 0.6);
    }

    .modal__btn:disabled {
        opacity: 1;
        background-color: hsla(0, 0%, 83%, 0.5);
    }

    .modal-bg,
    .modal__container {
        background-color: hsl(212, 28%, 18%);
    }

    .modal__close {
        &::before {
            color: hsl(236, 33%, 90%);
        }

        &:hover {
            background: hsla(0, 0%, 91%, 0.1);
        }
    }

    .modal-footer {
        box-shadow: inset 0 1px 0 hsla(0, 0%, 0%, 0.2);
    }

    #invite_user_form .modal-footer {
        /* no transparency prevents overlap issues */
        background-color: hsl(211, 28%, 14%);
    }

    .enter_sends,
    .enter_sends_choices {
        color: hsl(236, 33%, 90%);

        kbd {
            background-color: hsl(211, 29%, 14%);
            border-color: hsl(211, 29%, 14%);
            box-shadow: inset 0 -1px 0 hsl(210, 5%, 34%, 0.2);
            text-shadow: none;
            color: hsl(236, 33%, 90%);
        }

        .enter_sends_minor {
            color: hsl(0, 0%, 80%);
        }
    }

    table.table-striped thead.table-sticky-headers th {
        background-color: hsl(0, 0%, 0%);

        &[data-sort]:hover {
            background-color: hsl(211, 29%, 14%) !important;
        }
    }

    /* Extend the 'light-border' TippyJS theme, which is intended for
       popovers/menus that should use default background colors, to use
       our dark theme colors in Zulip's dark theme.
     */
    .tippy-box[data-theme~="light-border"] {
        .tippy-content a {
            color: hsl(236, 33%, 90%);

            &.compose_control_button:hover {
                color: hsl(200, 79%, 66%);
            }
        }

        &[data-placement^="top"] {
            > .tippy-arrow {
                &::before {
                    border-top-color: hsl(235, 18%, 7%);
                }
            }
        }

        &[data-placement^="bottom"] {
            > .tippy-arrow {
                &::before {
                    border-bottom-color: hsl(235, 18%, 7%);
                }
            }
        }

        &[data-placement^="left"] {
            > .tippy-arrow {
                &::before {
                    border-left-color: hsl(235, 18%, 7%);
                }
            }
        }

        &[data-placement^="right"] {
            > .tippy-arrow {
                &::before {
                    border-right-color: hsl(235, 18%, 7%);
                }
            }
        }
    }

    body,
    .app-main,
    .header-main,
    #message_view_header_underpadding,
    .floating_recipient .message-header-wrapper,
    .column-middle,
    #compose,
    .column-left .left-sidebar,
    .column-right .right-sidebar,
    #groups_overlay .right,
    #subscription_overlay .right,
    #settings_page .right,
    #streams_header,
    .private_messages_container {
        background-color: hsl(212, 28%, 18%);
    }

    #scroll-to-bottom-button-container {
        background: transparent;

        span {
            color: hsl(0, 0%, 27%);
        }
    }

    .compose-send-status-close {
        color: hsl(0, 0%, 100%);
        opacity: 1;
    }

    .compose-send-status-close:hover {
        opacity: 0.4;
    }

    .message_embed .data-container::after {
        background: linear-gradient(0deg, hsl(212, 28%, 18%), transparent 100%);
    }

    .column-left .left-sidebar,
    .stream_name_search_section,
    .group_name_search_section,
    .column-right .right-sidebar {
        border-color: hsla(0, 0%, 0%, 0.2);
    }

    .dark .message_label_clickable.stream_label,
    .dark .stream_label,
    .stream_label {
        color: hsl(212, 28%, 18%);
    }

    .new-style label.checkbox input[type="checkbox"] ~ span {
        border-color: hsla(0, 0%, 100%, 0.4);
    }

    .streams_popover .sp-container {
        background-color: transparent;

        button {
            background-color: hsl(208, 35%, 11%);
            border: 1px solid hsl(210, 36%, 4%);
            color: hsl(236, 31%, 90%);
        }

        .sp-picker-container {
            border-left: solid 1px hsl(210, 36%, 4%);
        }
    }

    /* this one is because in the app we have blue and in dark-theme it should be white. */
    .popover a {
        color: inherit;
    }

    .dark_background a,
    a.dark_background:hover,
    .dark_background,
    .message_reactions .message_reaction_count,
    .message_reactions .reaction_button i,
    .message_reactions:hover .message_reaction + .reaction_button {
        color: inherit !important;
    }

    /* It's a little annoying that we need to specify the different
       background colors for these, but this alert feature can't use a
       transparent background without creating other problems */
    .alert-msg {
        background-color: hsl(212, 28%, 18%);
    }

    .private-message .alert-msg {
        background-color: hsl(208, 17%, 29%);
    }

    .active_private_messages_section {
        #private_messages_section,
        #private_messages_list,
        #hide_more_private_messages {
            background-color: hsla(199, 33%, 46%, 0.2);
        }
    }

    /* do not turn the .message_header .stream_label text dark on hover because they're
       on a dark background, and don't change the dark labels dark either. */
    .message_header:not(.dark_background)
        a.stream_label:not(.dark_background):hover {
        color: hsl(212, 28%, 18%);
    }

    /* these are converting grey things to "new grey" */
    :disabled,
    input:not([type="radio"]):read-only,
    textarea:read-only,
    .recipient_row_date {
        color: inherit;
        opacity: 0.5;
    }

    .sidebar-title {
        color: inherit;
        opacity: 0.75;
    }

    .rendered_markdown button,
    .new-style .button {
        background-color: hsla(0, 0%, 0%, 0.2);

        &:not(.sea-green, .btn-danger, .btn-warning, .btn-link, .poll-vote) {
            border-color: hsla(0, 0%, 0%, 0.6);
            color: inherit;
        }

        &.btn-link {
            border-color: hsla(0, 0%, 0%, 0.6);
            color: hsl(200, 79%, 66%);
        }

        &:hover,
        &:focus,
        &:active {
            background-color: hsla(0, 0%, 0%, 0.15);
        }
    }

    .rendered_markdown .message_inline_image {
        background: hsla(0, 0%, 100%, 0.03);

        &:hover {
            background: hsla(0, 0%, 100%, 0.15);
        }
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="number"],
    input[type="url"],
    input[type="date"],
    textarea,
    .new-style .tab-switcher .ind-tab:not(.selected),
    select,
    .pill-container,
    .user_status_content_wrapper {
        background-color: hsla(0, 0%, 0%, 0.2);
        border-color: hsla(0, 0%, 0%, 0.6);
        color: inherit;
    }

    select option {
        background-color: hsl(212, 28%, 18%);
        color: hsl(236, 33%, 90%);
    }

    .unread_count,
    /* The actions_popover unread_count object has its own variable CSS,
       and thus needs to be repeated here with all three classes for precedence) */
    .actions_popover .mark_as_unread .unread_count {
        background-color: hsla(105, 2%, 50%, 0.5);
    }

    .pill-container {
        border-style: solid;
        border-width: 1px;
    }

    .deactivated-pill {
        background-color: hsl(0, 86%, 14%) !important;
    }

    #search_arrows .pill,
    .pm_recipient .pill-container .pill {
        color: inherit;
        border: 1px solid hsla(0, 0%, 0%, 0.5);
        background-color: hsla(0, 0%, 0%, 0.25);
        font-weight: 600;
    }

    #search_arrows .pill:focus,
    .pm_recipient .pill-container .pill:focus {
        color: hsl(0, 0%, 100%);
        border: 1px solid hsla(176, 78%, 28%, 0.6);
        background-color: hsla(176, 49%, 42%, 0.4);
    }

    .new-style .button.no-style {
        background-color: transparent;
    }

    .create_stream_plus_button {
        color: hsl(0, 0%, 100%);
        background-color: hsla(0, 0%, 0%, 0.2);
        border-color: hsla(0, 0%, 0%, 0.6);
    }

    .emoji-info-popover
        .emoji-popover
        .emoji-popover-category-tabs
        .emoji-popover-tab-item.active {
        background-color: hsla(0, 0%, 0%, 0.5);
    }

    .new-style .tab-switcher .ind-tab.selected,
    div.message_content thead,
    .table-striped thead th,
    .emoji-popover .reaction.reacted,
    .message_reactions .message_reaction.reacted {
        background-color: hsla(0, 0%, 0%, 0.5);
        border-color: hsla(0, 0%, 0%, 0.9);
    }

    .ind-tab.disabled {
        color: hsla(0, 0%, 42%, 0.9) !important;
    }

    .message_reactions:hover .message_reaction + .reaction_button,
    .message_reactions .message_reaction {
        background-color: transparent;
        border-color: hsla(0, 0%, 0%, 0.8);
        color: inherit;

        &:hover {
            border-color: hsl(236, 33%, 90%);
        }
    }

    /* Disable blue link styling for the message timestamp link. */
    .message_time {
        color: hsl(236, 33%, 90%);
    }

    .emoji-popover .reaction:focus {
        box-shadow: 0 0 1px hsl(0, 0%, 98%);
    }

    input[type="text"]:focus,
    input[type="email"]:focus,
    input[type="number"]:focus,
    textarea:focus,
    textarea.new_message_textarea:focus,
    .compose_table .recipient_box:focus {
        border-color: hsla(0, 0%, 0%, 0.9);
    }

    .message-header-contents,
    .message_header_private_message .message-header-contents {
        background-color: hsla(0, 0%, 0%, 0.2);
        border-color: transparent;
    }

    .compose_control_buttons_container .divider {
        color: hsla(236, 33%, 90%, 0.5);
    }

    /* Not that .message_row (below) needs to be more contrast on dark theme */
    #compose-content,
    .message_list .recipient_row,
    .message_row,
    .draft-row .draft-info-box,
    .preview_message_area {
        border-color: hsla(0, 0%, 0%, 0.2);
    }

    .recipient_row {
        .message_row.unread {
            .date_row {
                background-color: hsl(212, 28%, 18%);
            }
        }

        .private-message.unread {
            .date_row {
                background-color: hsl(208, 17%, 29%);
            }
        }

        .message_row.unread ~ .message_row.unread {
            .date_row {
                background-color: transparent;
            }
        }

        .private-message.unread ~ .private-message.unread {
            .date_row {
                background-color: hsl(208, 17%, 29%);
            }
        }
    }

    .spectator_narrow_login_button,
    .top-navbar-border {
        border-color: hsla(0, 0%, 0%, 0.6);
    }

    #message_view_header .sub_count {
        &::before,
        &::after {
            color: hsla(0, 0%, 100%, 0.5);
        }
    }

    #message_view_header .stream {
        color: hsl(236, 33%, 90%);
    }

    #message_view_header .sub_count,
    #message_view_header .narrow_description {
        color: hsla(0, 0%, 90%, 1);
    }

    div.overlay,
    #subscription_overlay
        #stream-creation
        #stream_creation_form
        #stream_creating_indicator:not(:empty),
    #groups_overlay
        #user-group-creation
        #user_group_creation_form
        #user_group_creating_indicator:not(:empty),
    .emoji-info-popover
        .emoji-popover
        .emoji-popover-emoji:not(.reacted):focus {
        background-color: hsla(212, 28%, 8%, 0.75);
    }

    div.overlay .flex.overlay-content > div,
    .dropdown-menu.typeahead,
    #settings_page,
    .informational-overlays .overlay-content {
        box-shadow: 0 0 30px hsl(212, 32%, 7%);
    }

    .tippy-box[data-theme~="light-border"],
    .dropdown-menu ul,
    .dropdown .dropdown-menu,
    .popover,
    .popover-title,
    .popover-content {
        background-color: hsl(212, 32%, 14%);
    }

    .dropdown-menu a {
        color: inherit;
    }

    .dropdown .dropdown-menu li.divider,
    .popover hr,
    hr {
        color: hsl(212, 28%, 18%);
        opacity: 0.2;
    }

    #gear_menu_about_zulip {
        .white_zulip_icon_without_text {
            filter: invert(10%) sepia(16%) saturate(175%) hue-rotate(194deg)
                brightness(99%) contrast(89%);
        }
    }

    #gear-menu {
        .dark-theme {
            display: none;
        }

        .light-theme {
            display: block;
        }

        .dropdown-menu a:hover {
            color: hsl(0, 0%, 100%);
        }
    }

    .nav .dropdown-menu::after,
    .popover.bottom .arrow {
        border-bottom-color: hsl(235, 18%, 7%);
    }

    .popover.left .arrow {
        border-left-color: hsl(235, 18%, 7%);
    }

    .popover.top .arrow {
        border-top-color: hsl(235, 18%, 7%);
    }

    .popover.right .arrow {
        border-right-color: hsl(235, 18%, 7%);
    }

    .narrow_to_compose_recipients,
    .expand_composebox_button,
    .collapse_composebox_button,
    #message_edit_tooltip,
    .clear_search_button,
    .clear_search_button:focus,
    .clear_search_button:active,
    .clear_search_button:disabled:hover,
    #user-groups .save-instructions,
    .close,
    #user_presences li:hover .user-list-sidebar-menu-icon,
    li.top_left_all_messages:hover .all-messages-sidebar-menu-icon,
    li.top_left_starred_messages:hover .starred-messages-sidebar-menu-icon,
    li.top_left_drafts:hover .drafts-sidebar-menu-icon,
    #stream_filters li:hover .stream-sidebar-menu-icon,
    li.topic-list-item:hover .topic-sidebar-menu-icon {
        color: hsl(236, 33%, 80%);
    }

    #message_edit_tooltip:hover,
    .clear_search_button:hover {
        color: hsl(0, 0%, 100%);
    }

    .spectator_login_buttons a {
        color: hsl(236, 33%, 90%);

        &:hover {
            color: hsl(0, 0%, 100%);
        }
    }

    .spectator_narrow_login_button .login_button i {
        color: hsl(236, 33%, 90%);
    }

    #user_presences li .user-list-sidebar-menu-icon:hover,
    .all-messages-sidebar-menu-icon:hover,
    .starred-messages-sidebar-menu-icon:hover,
    .drafts-sidebar-menu-icon:hover,
    .stream-sidebar-menu-icon:hover,
    .topic-sidebar-menu-icon:hover {
        color: hsl(0, 0%, 100%) !important;
    }

    #streamlist-toggle,
    #userlist-toggle {
        color: inherit;
        border-color: hsla(0, 0%, 0%, 0.6);
    }

    #streamlist-toggle-button {
        color: inherit;
        background-color: inherit;
    }

    #userlist-toggle-button {
        color: hsl(221, 9%, 54%);

        &:hover {
            color: inherit;
        }
    }

    li.active-filter,
    li.active-sub-filter {
        background-color: hsla(199, 33%, 46%, 0.2);
    }

    :not(.active-sub-filter) {
        &.top_left_row:hover,
        &.bottom_left_row:hover,
        &#stream_filters li.highlighted_stream {
            background-color: hsla(136, 25%, 73%, 0.2);
        }
    }

    #stream_filters .narrow-filter.active-filter {
        .topic-list .filter-topics,
        > .bottom_left_row {
            background-color: hsl(208, 31%, 24%);
        }
    }

    .zoom-in {
        #topics_header,
        .narrow-filter > .bottom_left_row,
        #stream_filters .topic-list .filter-topics {
            background-color: hsl(212, 28%, 18%);
        }
    }

    #topics_header {
        .show-all-streams {
            color: hsl(236, 33%, 90%);
            opacity: 0.75;
        }
    }

    #user_presences li:hover,
    #user_presences li.highlighted_user {
        background-color: hsla(136, 25%, 73%, 0.2);
    }

    .floating_recipient .recipient_row {
        border-top: none;
    }

    .group-row.active,
    .stream-row.active,
    .emoji-info-popover .emoji-showcase-container,
    .emoji-info-popover .emoji-popover .emoji-popover-category-tabs,
    .emoji-info-popover .emoji-popover .emoji-popover-top {
        background-color: hsla(0, 0%, 0%, 0.2);
    }

    .recent_topics_participant_overflow {
        color: hsl(0, 0%, 100%) !important;
        background-color: hsl(211, 18%, 25%) !important;
    }

    .recent_topics_container #recent_topics_table .btn-recent-filters {
        background-color: hsl(211, 29%, 14%);
        border-color: hsl(0, 0%, 0%);
        color: hsl(0, 0%, 100%);

        &:focus {
            background-color: hsla(0, 0%, 0%, 0.5) !important;
            outline: 0;
        }

        &.fake_disabled_button {
            cursor: not-allowed;
            opacity: 0.5;

            &:hover {
                background-color: hsla(0, 0%, 0%, 0.5);
                border-color: hsl(0, 0%, 0%);
            }
        }
    }

    .recent_topics_container {
        background-color: hsl(212, 28%, 18%) !important;
    }

    #recent_topics_table tr {
        background-color: hsl(212, 28%, 18%);

        &:hover {
            background-color: hsl(208, 26%, 11%, 0.6);
        }
    }

    #recent_topics_table .unread_topic {
        background-color: hsla(212, 30%, 22%, 0.6);
    }

    .btn-recent-selected,
    #recent_topics_table thead th {
        background-color: hsl(0, 0%, 0%) !important;

        &[data-sort]:hover {
            background-color: hsl(211, 29%, 14%) !important;
        }
    }

    #recent_topics_table td a {
        color: hsl(206, 89%, 74%);
        text-decoration: none;

        &:hover {
            color: hsl(208, 64%, 52%);
        }
    }

    #recent_topics_table {
        border-color: hsla(0, 0%, 0%, 0.6);

        .fa-envelope,
        .fa-group {
            opacity: 0.7;
        }
    }

    thead,
    .drafts-container .drafts-header,
    .nav > li > a:focus,
    .nav > li > a:hover,
    .subscriptions-container .subscriptions-header,
    .user-groups-container .user-groups-header,
    .grey-box,
    .white-box,
    .stream-email,
    #settings_page .settings-header,
    #settings_page .sidebar li.active,
    #settings_page .sidebar-wrapper .tab-container,
    .table-striped tbody tr:nth-child(even) td,
    .table-striped tbody tr:nth-child(odd) th,
    .modal-footer,
    .modal-bg .modal-header {
        border-color: hsla(0, 0%, 0%, 0.2);
        background-color: hsla(0, 0%, 0%, 0.2);
    }

    .table-striped tbody tr:nth-child(odd) td {
        background-color: hsl(212, 28%, 18%);
    }

    .user-groups-container .right .display-type,
    .subscriptions-container .right .display-type,
    .stream-row,
    .group-row,
    .subscriptions-container .left .search-container,
    .subscriptions-container .left,
    .user-groups-container .left,
    .subscriber-list-box,
    .subscriber-list-box .subscriber_list_container .subscriber-list tr,
    .member-list-box,
    .member-list-box .member_list_container .member-list tr,
    #subscription_overlay .subsection-parent div,
    #subscription_overlay .radio-input-parent,
    #stream_privacy_modal .radio-input-parent,
    #settings_page .sidebar-wrapper,
    #settings_page .sidebar-wrapper *,
    table,
    table th,
    table td {
        border-color: hsla(0, 0%, 0%, 0.2);
    }

    .draft-row .draft-info-box,
    .message_header_private_message .message-header-contents {
        box-shadow: none;
    }

    .draft-row .message_header_private_message .message_label_clickable {
        padding: 4px 6px 3px;
        color: inherit;
    }

    .nav-list > li > a,
    .nav-list .nav-header {
        text-shadow: none;
    }

    .group_mention .messagebox,
    .direct_mention .messagebox {
        background-color: hsla(8, 78%, 43%, 0.15);
    }

    .rendered_markdown {
        .user-mention,
        .user-group-mention {
            background: linear-gradient(
                to bottom,
                hsla(0, 0%, 0%, 0.2) 0%,
                hsla(0, 0%, 0%, 0.1) 100%
            );
            box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.4);
        }

        .user-mention-me :not(.silent) {
            background-color: hsla(355, 37%, 31%, 1);
            box-shadow: 0 0 0 1px hsla(330, 40%, 20%, 1);
        }

        .codehilite code,
        .codehilite pre {
            color: hsl(212, 100%, 82%);
            background-color: hsl(212, 25%, 15%);
        }

        .codehilite .hll {
            background-color: hsl(0, 0%, 13%);
        }

        .codehilite .err {
            color: hsl(1, 67%, 66%);
            background-color: hsl(0, 7%, 22%);
        }

        .codehilite .k {
            color: hsl(31, 85%, 59%);
        }

        .codehilite .p {
            color: hsl(179, 27%, 35%);
        }

        .codehilite .cs {
            color: hsl(0, 100%, 40%);
            font-weight: 700;
        }

        .codehilite .gd {
            color: hsl(0, 100%, 40%);
        }

        .codehilite .ge {
            color: hsl(0, 0%, 80%);
            font-style: italic;
        }

        .codehilite .gr {
            color: hsl(0, 100%, 50%);
        }

        .codehilite .go {
            color: hsl(0, 0%, 50%);
        }

        .codehilite .gs {
            color: hsl(0, 0%, 80%);
            font-weight: 700;
        }

        .codehilite .gu {
            color: hsl(300, 100%, 25%);
            font-weight: 700;
        }

        .codehilite .gt {
            color: hsl(222, 100%, 41%);
        }

        .codehilite .kc {
            color: hsl(0, 45%, 75%);
        }

        .codehilite .kd {
            color: hsl(60, 100%, 76%);
        }

        .codehilite .kn {
            color: hsl(24, 56%, 72%);
            font-weight: 700;
        }

        .codehilite .kp {
            color: hsl(62, 36%, 71%);
        }

        .codehilite .kr {
            color: hsl(359, 58%, 56%);
        }

        .codehilite .ni {
            color: hsl(359, 35%, 63%);
        }

        .codehilite .ne {
            color: hsl(53, 23%, 69%);
            font-weight: 700;
        }

        .codehilite .nn {
            color: hsl(204, 54%, 72%);
        }

        .codehilite .vi {
            color: hsl(60, 100%, 89%);
        }

        .codehilite .c,
        .codehilite .g,
        .codehilite .cm,
        .codehilite .cp,
        .codehilite .c1 {
            color: hsl(209, 15%, 55%);
        }

        .codehilite .l,
        .codehilite .x,
        .codehilite .no,
        .codehilite .nd,
        .codehilite .nl,
        .codehilite .nx,
        .codehilite .py,
        .codehilite .w {
            color: hsl(0, 0%, 80%);
        }

        .codehilite .n,
        .codehilite .nv,
        .codehilite .vg {
            color: hsl(60, 19%, 83%);
        }

        .codehilite .o,
        .codehilite .ow {
            color: hsl(58, 52%, 88%);
        }

        .codehilite .gh,
        .codehilite .gp {
            color: hsl(60, 19%, 83%);
            font-weight: 700;
        }

        .codehilite .gi,
        .codehilite .kt {
            color: hsl(120, 100%, 40%);
        }

        .codehilite .ld,
        .codehilite .s,
        .codehilite .sb,
        .codehilite .sc,
        .codehilite .sd,
        .codehilite .s2,
        .codehilite .se,
        .codehilite .sh,
        .codehilite .si,
        .codehilite .sx,
        .codehilite .sr,
        .codehilite .s1,
        .codehilite .ss {
            color: hsl(0, 36%, 69%);
        }

        .codehilite .m,
        .codehilite .mf,
        .codehilite .mh,
        .codehilite .mi,
        .codehilite .mo,
        .codehilite .il {
            color: hsl(183, 45%, 69%);
        }

        .codehilite .na,
        .codehilite .nt {
            color: hsl(127, 25%, 68%);
        }

        .codehilite .nb,
        .codehilite .nc,
        .codehilite .nf,
        .codehilite .bp,
        .codehilite .vc {
            color: hsl(60, 75%, 75%);
        }
    }

    #message-edit-history {
        .message_edit_history_content {
            .highlight_text_inserted {
                color: hsl(122, 100%, 81%);
                background-color: hsla(120, 64%, 95%, 0.3);
            }

            .highlight_text_deleted {
                color: hsl(0, 90%, 67%);
                background-color: hsla(7, 54%, 62%, 0.38);
            }
        }
    }

    time {
        background: hsla(0, 0%, 0%, 0.2);
        box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.4);
    }

    .upgrade-tip,
    .upgrade-or-sponsorship-tip,
    .tip {
        color: inherit;
        background-color: hsla(46, 28%, 38%, 0.27);
        border: 1px solid hsl(49, 38%, 46%);
    }

    #request-progress-status-banner {
        background-color: hsl(212, 32%, 14%);

        .alert-content {
            color: hsl(236, 33%, 90%);
        }
    }

    .alert.home-error-bar {
        color: hsl(236, 33%, 90%);
        background-color: hsla(35, 84%, 62%, 0.25);
        border: 1px solid hsl(11, 46%, 54%);
    }

    .alert {
        text-shadow: none;

        .close {
            color: inherit;
            opacity: 0.8;
        }

        .close:hover {
            opacity: 1;
        }
    }

    .alert.alert-success {
        color: inherit;
        background-color: hsla(161, 60%, 46%, 0.2);
        border-color: hsl(165, 68%, 37%);
    }

    .alert.alert-error,
    .alert.alert-danger {
        background-color: hsl(318, 12%, 21%);
        color: inherit;
        border: 1px solid hsl(0, 75%, 65%);
    }

    .alert-box .alert,
    .alert-box .stacktrace,
    .alert.alert-error {
        background-color: hsl(318, 12%, 21%);
        color: inherit;
        border: 1px solid hsl(0, 75%, 65%);
    }

    .alert-box {
        .alert.alert-error::before {
            color: hsl(0, 75%, 65%);
        }

        .stacktrace {
            color: hsl(314, 22%, 85%);

            .expand {
                color: hsl(318, 14%, 36%);
            }

            .subtle {
                color: hsl(314, 19%, 63%);
            }

            .code-context {
                color: hsl(314, 27%, 82%);
                background-color: hsl(312, 7%, 14%);
                box-shadow: inset 0 11px 10px -10px hsl(0, 0%, 6%),
                    inset 0 -11px 10px -10px hsl(0, 0%, 6%);

                .line-number {
                    color: hsl(318, 14%, 44%);
                }

                .focus-line {
                    background-color: hsl(307, 9%, 19%);
                }
            }
        }
    }

    /* Popover: */
    .hotspot.overlay .hotspot-popover {
        border-color: hsla(0, 0%, 0%, 0.2) !important;
        /* Based on the `.hotspot-popover` shadow in `hotspots.css`, but with a new
    color. */
        box-shadow: 0 5px 10px hsla(0, 0%, 0%, 0.4);
    }

    #user-profile-modal {
        #default-section {
            .default-field {
                .name {
                    color: hsl(236, 33%, 90%);
                }
            }
        }

        #content {
            .field-section {
                .name {
                    color: hsl(236, 33%, 90%);
                }
            }
        }

        .subscription-group-list,
        .subscription-stream-list,
        .subscription-stream-list .user-stream-list tr,
        .subscription-group-list .user-group-list tr {
            border-color: hsla(0, 0%, 0%, 0.4);
        }
    }

    /* Arrows: */
    .hotspot.overlay {
        .hotspot-popover.arrow-right::before {
            border-left-color: hsla(0, 0%, 0%, 0.2);
        }

        .hotspot-popover.arrow-right::after {
            border-left-color: hsl(212, 28%, 18%);
        }

        .hotspot-popover.arrow-bottom::before {
            border-top-color: hsla(0, 0%, 0%, 0.2);
        }

        .hotspot-popover.arrow-bottom::after {
            border-top-color: hsl(212, 28%, 18%);
        }

        .hotspot-popover.arrow-left::before {
            border-right-color: hsla(0, 0%, 0%, 0.2);
        }

        .hotspot-popover.arrow-left::after {
            border-right-color: hsl(212, 28%, 18%);
        }

        .hotspot-popover.arrow-top::before {
            border-bottom-color: hsla(0, 0%, 0%, 0.2);
        }

        .hotspot-popover.arrow-top::after {
            border-bottom-color: hsl(212, 28%, 18%);
        }
    }

    /* Content: */
    .hotspot.overlay .hotspot-popover .hotspot-popover-content,
    .hotspot.overlay .hotspot-popover .hotspot-popover-bottom {
        background-color: hsl(212, 28%, 18%);
    }

    .alert-zulip-logo,
    .top-messages-logo,
    .bottom-messages-logo {
        svg path {
            fill: hsl(214, 27%, 18%);
            stroke: hsl(214, 27%, 18%);
        }

        svg circle {
            fill: hsl(0, 0%, 100%);
            stroke: hsl(0, 0%, 100%);
        }
    }

    .history-limited-box,
    .all-messages-search-caution {
        background-color: hsla(0, 0%, 0%, 0.2);
    }

    #feedback_container,
    code,
    .typeahead.dropdown-menu {
        background-color: hsl(212, 25%, 15%);
        border-color: hsla(0, 0%, 0%, 0.5);
        color: inherit;
    }

    /* Search highlight used in both topics and rendered_markdown */
    .highlight {
        background-color: hsla(51, 100%, 64%, 0.42);
    }

    .sub-unsub-message span::before,
    .sub-unsub-message span::after,
    .date_row span::before,
    .date_row span::after,
    .streams_subheader span::before,
    .streams_subheader span::after {
        opacity: 0.2;
    }

    .star:not(.empty-star),
    .empty-star:hover {
        color: hsla(126, 66%, 72%, 0.75);
    }

    #out-of-view-notification {
        border: 1px solid hsl(144, 45%, 62%);
    }

    #bots_lists_navbar .active a {
        color: hsl(0, 0%, 87%);
        background-color: hsl(212, 28%, 18%);
        border-color: hsl(0, 0%, 87%);
        border-bottom-color: transparent;
    }

    .searching-for-more-topics img {
        filter: invert(100%);
    }

    .simplebar-track .simplebar-scrollbar::before {
        background-color: hsl(0, 0%, 100%);
        box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.33);
    }

    .collapse-settings-btn:hover {
        color: hsl(200, 79%, 66%);
    }

    #request-progress-status-banner .loading-indicator,
    #loading_older_messages_indicator,
    #recent_topics_loading_messages_indicator {
        path {
            fill: hsl(0, 0%, 100%);
        }
    }

    .small_square_button {
        &.small_square_x {
            background-color: hsl(0, 0%, 95%);
            color: hsl(0, 0%, 42%);

            &:hover {
                color: hsl(0, 0%, 18%);
            }
        }
    }

    a:not(:active):focus,
    button:focus,
    .new-style label.checkbox input[type="checkbox"]:focus ~ span,
    i.fa:focus,
    i.zulip-icon:focus,
    .auto-select:focus,
    [role="button"]:focus {
        outline-color: hsl(0, 0%, 67%);
    }

    .animated-purple-button:focus {
        box-shadow: 0 1px 4px 0 hsl(0, 0%, 100%, 0.666);
    }

    .color_animated_button {
        background-color: hsl(211, 29%, 14%);

        * {
            color: hsl(0, 0%, 100%);
        }

        &:hover {
            background-color: hsl(240, 96%, 68%);
        }
    }

    /* Widgets: Poll */
    .poll-widget {
        .poll-vote {
            color: hsl(185, 35%, 65%);
            border-color: hsl(185, 35%, 35%);

            &:hover {
                color: hsl(185, 50%, 70%);
                border-color: hsl(185, 40%, 40%);
                background-color: hsl(185, 20%, 20%);
            }

            &:focus {
                color: hsl(185, 50%, 65%);
                border-color: hsl(185, 40%, 50%);
                background-color: hsl(185, 40%, 35%);
            }
        }

        .poll-names {
            color: hsl(236, 15%, 70%);
        }
    }

    /* Originally the icon inherits the color of label, but when the setting
    is disabled, the color of the label is changed and thus the icon becomes
    too light. So, we set the color explicitly to original color of label to
    keep the color of the icon same even when the setting is disabled. */
    #id_realm_enable_spectator_access_label a {
        color: hsl(236, 33%, 90%);
    }

    #stream-specific-notify-table .unmute_stream {
        &:hover {
            color: hsl(0, 0%, 100%);
        }
    }

    #read_receipts_modal #read_receipts_list li {
        &:hover {
            background-color: hsla(0, 0%, 100%, 0.05);
        }

        &:active,
        &:focus {
            background-color: hsla(0, 0%, 100%, 0.1);
        }
    }
}

@supports (-moz-appearance: none) {
    :root.dark-theme #settings_page select {
        background-color: hsla(0, 0%, 0%, 0.2);
    }
}

@media (prefers-color-scheme: dark) {
    :root.color-scheme-automatic {
        @extend :root.dark-theme;
    }
}
