#message-edit-history {
    .message_time {
        position: static;
    }

    .message_author {
        position: relative;
    }

    .author_details {
        display: block;
        font-size: 12px;
        padding: 1px;
        text-align: right;
    }

    .messagebox-content {
        padding: 0 10px;
    }

    .message_edit_history_content {
        .highlight_text_inserted {
            color: hsl(122, 72%, 30%);
            background-color: hsl(120, 64%, 95%);
        }

        .highlight_text_deleted {
            color: hsl(0, 100%, 50%);
            background-color: hsl(7, 90%, 92%);
            text-decoration: line-through;
            word-break: break-all;
        }
    }
}
